@import "assets/sass/custom-palettes";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;600;700&display=swap');
@import '@angular/cdk/overlay-prebuilt.css';

:root {
    --white: #fff;
    --color-text-04: #c1c1c4;
    --vds-container-picker-border-focus: hsla(199, 100%, 34%, 1);
    --vds-container-picker-border-hover: hsla(199, 100%, 29%, 1);
    --vds-container-picker-text-default: hsla(206, 12%, 7%, 1);
    --vds-container-picker-text-readonly: hsla(206, 12%, 34%, 1);
    --vds-container-picker-text-selected: #ffffff;
    --vds-feedback-notification-color-bg: #ffffff;
    --vds-feedback-notification-color-border-info: hsla(199, 100%, 16%, 1);
    --vds-feedback-notification-color-border-interactive: hsla(199, 100%, 34%, 1);
    --vds-feedback-notification-color-border-error: hsla(0, 100%, 41%, 1);
    --vds-feedback-notification-color-border-success: hsla(136, 55%, 34%, 1);
    --vds-feedback-notification-color-border-warning: hsla(41, 75%, 34%, 1);
    --vds-feedback-notification-color-text: hsla(206, 12%, 7%, 1);
    --vds-feedback-badge-bg-alert: hsla(0, 100%, 41%, 1);
    --vds-feedback-badge-bg-counter: hsla(206, 12%, 66%, 1);
    --vds-feedback-badge-bg-success: hsla(136, 55%, 34%, 1);
    --vds-feedback-badge-bg-warning: hsla(41, 75%, 34%, 1);
    --vds-feedback-badge-bg-info: hsla(199, 100%, 16%, 1);
    --vds-feedback-badge-text-default: #ffffff;
    --vds-feedback-badge-text-counter: hsla(206, 12%, 7%, 1);
    --vds-feedback-badge-border-default: #ffffff;
    --vds-feedback-load-spinner-color: hsla(199, 100%, 34%, 1);
    --vds-feedback-load-text: hsla(206, 12%, 7%, 1);
    --vds-feedback-load-progress-track: hsla(206, 12%, 66%, 1);
    --vds-feedback-load-progress-bar-percent: hsla(199, 100%, 34%, 1);
    --vds-feedback-load-progress-bar-full: hsla(136, 55%, 34%, 1);
    --vds-feedback-load-progress-bar-error: hsla(0, 100%, 41%, 1);
    --vds-nav-link-color-text-default: hsla(199, 100%, 34%, 1);
    --vds-nav-link-color-text-hover: hsla(199, 100%, 29%, 1);
    --vds-nav-link-color-focus: hsla(199, 100%, 34%, 1);
    --vds-nav-tabs-color-bg-primary-default: #ffffff;
    --vds-nav-tabs-color-bg-primary-progressive: hsla(206, 12%, 96%, 1);
    --vds-nav-tabs-color-bg-primary-disabled: hsla(206, 12%, 81%, 1);
    --vds-nav-tabs-color-bg-primary-hover: hsla(199, 100%, 91%, 1);
    --vds-nav-tabs-color-bg-primary-current-default: hsla(199, 100%, 34%, 1);
    --vds-nav-tabs-color-bg-primary-current-hover: hsla(199, 100%, 41%, 1);
    --vds-nav-tabs-color-bg-primary-active: hsla(199, 100%, 86%, 1);
    --vds-nav-tabs-color-bg-secondary-default: rgba(0, 0, 0, 0);
    --vds-nav-tabs-color-bg-secondary-focus: rgba(0, 0, 0, 0);
    --vds-nav-tabs-color-bg-secondary-hover: hsla(199, 100%, 91%, 1);
    --vds-nav-tabs-color-bg-secondary-current: hsla(199, 100%, 86%, 1);
    --vds-nav-tabs-color-text-default: hsla(199, 100%, 34%, 1);
    --vds-nav-tabs-color-text-active: hsla(199, 100%, 29%, 1);
    --vds-nav-tabs-color-text-current: #ffffff;
    --vds-nav-tabs-color-text-disabled: hsla(206, 12%, 61%, 1);
    --vds-nav-tabs-color-text-inactive: hsla(206, 12%, 7%, 1);
    --vds-nav-tabs-color-text-secondary-disabled: hsla(206, 12%, 81%, 1);
    --vds-nav-tabs-color-text-hover: hsla(199, 100%, 29%, 1);
    --vds-nav-tabs-color-border-focus: hsla(199, 100%, 34%, 1);
    --vds-nav-tabs-color-border-group: hsla(206, 12%, 66%, 1);
    --vds-nav-switch-color-bg-default: #ffffff;
    --vds-nav-switch-color-bg-hover: hsla(199, 100%, 91%, 1);
    --vds-nav-switch-color-bg-current: hsla(199, 100%, 23%, 1);
    --vds-nav-switch-color-border-hover: hsla(199, 100%, 29%, 1);
    --vds-nav-switch-color-border-focus: hsla(199, 100%, 34%, 1);
    --vds-nav-switch-color-border-default: hsla(199, 100%, 34%, 1);
    --vds-nav-switch-color-text-default: hsla(199, 100%, 34%, 1);
    --vds-nav-switch-color-text-hover: hsla(199, 100%, 29%, 1);
    --vds-nav-switch-color-text-selected: #ffffff;
    --vds-nav-stepper-color-step-bg-default: #ffffff;
    --vds-nav-stepper-color-step-bg-disabled: hsla(206, 12%, 81%, 1);
    --vds-nav-stepper-color-step-bg-success-default: hsla(136, 55%, 34%, 1);
    --vds-nav-stepper-color-step-bg-success-hover: hsla(136, 55%, 29%, 1);
    --vds-nav-stepper-color-step-text-default: hsla(206, 12%, 34%, 1);
    --vds-nav-stepper-color-step-text-current-default: hsla(199, 100%, 34%, 1);
    --vds-nav-stepper-color-step-text-current-hover: hsla(199, 100%, 29%, 1);
    --vds-nav-stepper-color-step-text-disabled: hsla(206, 12%, 81%, 1);
    --vds-nav-stepper-color-step-text-done: #ffffff;
    --vds-nav-stepper-color-step-border-default: hsla(206, 12%, 55%, 1);
    --vds-nav-stepper-color-step-border-hover: hsla(206, 12%, 34%, 1);
    --vds-nav-stepper-color-step-border-disabled: hsla(206, 12%, 81%, 1);
    --vds-nav-stepper-color-step-border-current-default: hsla(199, 100%, 34%, 1);
    --vds-nav-stepper-color-step-border-current-hover: hsla(199, 100%, 29%, 1);
    --vds-nav-stepper-color-step-border-done: hsla(199, 100%, 34%, 1);
    --vds-nav-stepper-color-step-border-focus: hsla(199, 100%, 34%, 1);
    --vds-nav-stepper-color-text-title: hsla(206, 12%, 7%, 1);
    --vds-nav-stepper-color-text-desc: hsla(206, 12%, 34%, 1);
    --vds-nav-stepper-color-text-disabled: hsla(206, 12%, 81%, 1);
    --vds-nav-title-bar-color-bg: #ffffff;
    --vds-nav-title-bar-color-text-title: hsla(206, 12%, 7%, 1);
    --vds-nav-title-bar-color-text-description: hsla(206, 12%, 34%, 1);
    --vds-nav-title-bar-color-border: hsla(199, 100%, 34%, 1);
    --vds-nav-shell-bg: hsla(199, 100%, 7%, 1);
    --vds-nav-shell-text: #ffffff;
    --vds-nav-shell-menu-text-default: #ffffff;
    --vds-nav-shell-menu-text-hover: #ffffff;
    --vds-nav-shell-menu-text-active: #ffffff;
    --vds-nav-shell-menu-bg-default: hsla(199, 100%, 7%, 1);
    --vds-nav-shell-menu-bg-hover: hsla(199, 100%, 16%, 1);
    --vds-nav-shell-menu-bg-active: hsla(199, 100%, 23%, 1);
    --vds-nav-shell-menu-bg-selected-default: hsla(199, 100%, 86%, 1);
    --vds-nav-shell-menu-bg-selected-hover: hsla(199, 100%, 91%, 1);
    --vds-nav-shell-menu-bg-selected-active: hsla(199, 100%, 96%, 1);
    --vds-nav-breadcrumb-bg-default: rgba(0, 0, 0, 0);
    --vds-nav-breadcrumb-bg-hover: hsla(199, 100%, 91%, 1);
    --vds-nav-breadcrumb-bg-focus: hsla(199, 100%, 34%, 1);
    --vds-nav-breadcrumb-bg-active: hsla(199, 100%, 86%, 1);
    --vds-nav-breadcrumb-text-default: hsla(199, 100%, 34%, 1);
    --vds-nav-breadcrumb-text-active: hsla(199, 100%, 29%, 1);
    --vds-nav-breadcrumb-text-hover: hsla(199, 100%, 29%, 1);
    --vds-nav-breadcrumb-text-readonly: hsla(206, 12%, 7%, 1);
    --vds-ctrl-color-text-label: hsla(206, 12%, 7%, 1);
    --vds-ctrl-color-text-disabled: hsla(206, 12%, 81%, 1);
    --vds-ctrl-color-text-icon-selected: #ffffff;
    --vds-ctrl-color-text-icon-disabled: hsla(206, 12%, 61%, 1);
    --vds-ctrl-color-border-input-default: hsla(199, 100%, 34%, 1);
    --vds-ctrl-color-border-input-hover: hsla(199, 100%, 29%, 1);
    --vds-ctrl-color-border-input-focus: hsla(199, 100%, 34%, 1);
    --vds-ctrl-color-border-input-disabled: hsla(206, 12%, 81%, 1);
    --vds-ctrl-color-bg-input-selected-default: hsla(199, 100%, 34%, 1);
    --vds-ctrl-color-bg-input-selected-hover: hsla(199, 100%, 29%, 1);
    --vds-ctrl-color-bg-input-default: #ffffff;
    --vds-ctrl-color-bg-input-hover: hsla(199, 100%, 91%, 1);
    --vds-ctrl-color-bg-input-disabled: hsla(206, 12%, 81%, 1);
    --vds-ctrl-color-bg-toggle-inactive-default: hsla(206, 12%, 34%, 1);
    --vds-ctrl-color-bg-toggle-inactive-hover: hsla(206, 12%, 34%, 1);
    --vds-ctrl-color-bg-toggle-handle: #ffffff;
    --vds-ctrl-color-bg-toggle-active-default: hsla(199, 100%, 34%, 1);
    --vds-ctrl-color-bg-toggle-active-hover: hsla(199, 100%, 29%, 1);
    --vds-utilities-avatar-bg-tenant-default: hsla(199, 100%, 34%, 1);
    --vds-utilities-avatar-bg-tenant-hover: hsla(199, 100%, 29%, 1);
    --vds-utilities-avatar-bg-company-default: hsla(206, 12%, 86%, 1);
    --vds-utilities-avatar-bg-company-hover: hsla(206, 12%, 81%, 1);
    --vds-utilities-avatar-content-tenant: #ffffff;
    --vds-utilities-avatar-content-company: hsla(206, 12%, 7%, 1);
    --vds-utilities-divider-border: hsla(206, 12%, 66%, 1);
    --vds-dataviz-table-border-default: hsla(206, 12%, 66%, 1);
    --vds-dataviz-table-border-focus: hsla(199, 100%, 34%, 1);
    --vds-dataviz-table-border-interactive: hsla(199, 100%, 34%, 1);
    --vds-dataviz-table-border-error-hover: hsla(0, 100%, 34%, 1);
    --vds-dataviz-table-border-error-default: hsla(0, 100%, 41%, 1);
    --vds-dataviz-table-bg-default: #ffffff;
    --vds-dataviz-table-bg-hover: hsla(199, 100%, 91%, 1);
    --vds-dataviz-table-bg-active: hsla(199, 100%, 86%, 1);
    --vds-dataviz-table-bg-fixed: hsla(206, 12%, 91%, 1);
    --vds-dataviz-table-bg-error-default: hsla(0, 100%, 91%, 1);
    --vds-dataviz-table-bg-error-hover: hsla(0, 100%, 86%, 1);
    --vds-dataviz-table-text-content: hsla(206, 12%, 7%, 1);
    --vds-dataviz-table-text-desc: hsla(206, 12%, 34%, 1);
    --vds-dataviz-table-text-error-default: hsla(0, 100%, 41%, 1);
    --vds-dataviz-table-text-error-hover: hsla(0, 100%, 34%, 1);
    --vds-global-border-alpha: rgba(0, 0, 0, 0);
    --vds-global-border-focus: hsla(199, 100%, 34%, 1);
    --vds-text-headings-display-font-family: Cairo;
    --vds-text-headings-display-font-weight: 700;
    --vds-text-headings-display-line-height: 1.67;
    --vds-text-headings-display-font-size: 50px;
    --vds-text-headings-page-font-family: Cairo;
    --vds-text-headings-page-font-weight: 700;
    --vds-text-headings-page-line-height: 1.5;
    --vds-text-headings-page-font-size: 42px;
    --vds-text-headings-section-font-family: Cairo;
    --vds-text-headings-section-font-weight: 700;
    --vds-text-headings-section-line-height: 1.5;
    --vds-text-headings-section-font-size: 32px;
    --vds-text-headings-subtitle-font-family: Cairo;
    --vds-text-headings-subtitle-font-weight: 700;
    --vds-text-headings-subtitle-line-height: 1.5;
    --vds-text-headings-subtitle-font-size: 24px;
    --vds-text-headings-subsection-font-family: Cairo;
    --vds-text-headings-subsection-font-weight: 700;
    --vds-text-headings-subsection-line-height: 1.5;
    --vds-text-headings-subsection-font-size: 20px;
    --vds-text-headings-eyebrows-font-family: Cairo;
    --vds-text-headings-eyebrows-font-weight: 400;
    --vds-text-headings-eyebrows-line-height: 1.5;
    --vds-text-headings-eyebrows-font-size: 14px;
    --vds-text-headings-interface-small-regular-font-family: Cairo;
    --vds-text-headings-interface-small-regular-font-weight: 400;
    --vds-text-headings-interface-small-regular-line-height: 1;
    --vds-text-headings-interface-small-regular-font-size: 16px;
    --vds-text-headings-interface-small-strong-font-family: Cairo;
    --vds-text-headings-interface-small-strong-font-weight: 500;
    --vds-text-headings-interface-small-strong-line-height: 1;
    --vds-text-headings-interface-small-strong-font-size: 16px;
    --vds-text-headings-interface-small-heavy-font-family: Cairo;
    --vds-text-headings-interface-small-heavy-font-weight: 700;
    --vds-text-headings-interface-small-heavy-line-height: 1;
    --vds-text-headings-interface-small-heavy-font-size: 16px;
    --vds-text-headings-interface-compact-default-font-family: Cairo;
    --vds-text-headings-interface-compact-default-font-weight: 400;
    --vds-text-headings-interface-compact-default-line-height: 1;
    --vds-text-headings-interface-compact-default-font-size: 14px;
    --vds-text-headings-interface-compact-hover-font-family: Cairo;
    --vds-text-headings-interface-compact-hover-font-weight: 500;
    --vds-text-headings-interface-compact-hover-line-height: 1;
    --vds-text-headings-interface-compact-hover-font-size: 14px;
    --vds-text-headings-interface-compact-selected-font-family: Cairo;
    --vds-text-headings-interface-compact-selected-font-weight: 700;
    --vds-text-headings-interface-compact-selected-line-height: 1;
    --vds-text-headings-interface-compact-selected-font-size: 14px;
    --vds-text-headings-interface-large-regular-font-family: Cairo;
    --vds-text-headings-interface-large-regular-font-weight: 400;
    --vds-text-headings-interface-large-regular-line-height: 1;
    --vds-text-headings-interface-large-regular-font-size: 24px;
    --vds-text-headings-interface-large-strong-font-family: Cairo;
    --vds-text-headings-interface-large-strong-font-weight: 500;
    --vds-text-headings-interface-large-strong-line-height: 1;
    --vds-text-headings-interface-large-strong-font-size: 24px;
    --vds-text-headings-interface-large-heavy-font-family: Cairo;
    --vds-text-headings-interface-large-heavy-font-weight: 700;
    --vds-text-headings-interface-large-heavy-line-height: 1;
    --vds-text-headings-interface-large-heavy-font-size: 24px;
    --vds-text-body-large-regular-font-family: Roboto;
    --vds-text-body-large-regular-font-weight: 400;
    --vds-text-body-large-regular-line-height: 1.5;
    --vds-text-body-large-regular-font-size: 16px;
    --vds-text-body-large-heavy-font-family: Roboto;
    --vds-text-body-large-heavy-font-weight: 700;
    --vds-text-body-large-heavy-line-height: 1.5;
    --vds-text-body-large-heavy-font-size: 16px;
    --vds-text-body-small-regular-font-family: Roboto;
    --vds-text-body-small-regular-font-weight: 400;
    --vds-text-body-small-regular-line-height: 1.5;
    --vds-text-body-small-regular-font-size: 14px;
    --vds-text-body-small-heavy-font-family: Roboto;
    --vds-text-body-small-heavy-font-weight: 700;
    --vds-text-body-small-heavy-line-height: 1.5;
    --vds-text-body-small-heavy-font-size: 14px;
    --vds-text-body-helper-regular-font-family: Roboto;
    --vds-text-body-helper-regular-font-weight: 400;
    --vds-text-body-helper-regular-line-height: 1.25;
    --vds-text-body-helper-regular-font-size: 12px;
    --vds-text-body-helper-strong-font-family: Roboto;
    --vds-text-body-helper-strong-font-weight: 500;
    --vds-text-body-helper-strong-line-height: 1.25;
    --vds-text-body-helper-strong-font-size: 12px;
    --vds-text-body-helper-heavy-font-family: Roboto;
    --vds-text-body-helper-heavy-font-weight: 700;
    --vds-text-body-helper-heavy-line-height: 1.25;
    --vds-text-body-helper-heavy-font-size: 12px;
    --vds-text-body-caption-default-font-family: Roboto;
    --vds-text-body-caption-default-font-weight: 400;
    --vds-text-body-caption-default-line-height: 1.5;
    --vds-text-body-caption-default-font-size: 10px;
    --vds-text-body-caption-strong-font-family: Roboto;
    --vds-text-body-caption-strong-font-weight: 500;
    --vds-text-body-caption-strong-line-height: 1.5;
    --vds-text-body-caption-strong-font-size: 10px;
    --vds-text-body-caption-heavy-font-family: Roboto;
    --vds-text-body-caption-heavy-font-weight: 700;
    --vds-text-body-caption-heavy-line-height: 1.5;
    --vds-text-body-caption-heavy-font-size: 10px;
    --vds-text-body-interface-large-regular-font-family: Roboto;
    --vds-text-body-interface-large-regular-font-weight: 400;
    --vds-text-body-interface-large-regular-line-height: 1;
    --vds-text-body-interface-large-regular-font-size: 16px;
    --vds-text-body-interface-large-regular2-font-family: Roboto;
    --vds-text-body-interface-large-regular2-font-weight: 400;
    --vds-text-body-interface-large-regular2-line-height: 1;
    --vds-text-body-interface-large-regular2-font-size: 16px;
    --vds-text-body-interface-large-extended-font-family: Roboto;
    --vds-text-body-interface-large-extended-font-weight: 400;
    --vds-text-body-interface-large-extended-line-height: 1.5;
    --vds-text-body-interface-large-extended-font-size: 16px;
    --vds-text-body-interface-large-strong-font-family: Roboto;
    --vds-text-body-interface-large-strong-font-weight: 500;
    --vds-text-body-interface-large-strong-line-height: 1;
    --vds-text-body-interface-large-strong-font-size: 16px;
    --vds-text-body-interface-large-heavy-font-family: Roboto;
    --vds-text-body-interface-large-heavy-font-weight: 700;
    --vds-text-body-interface-large-heavy-line-height: 1;
    --vds-text-body-interface-large-heavy-font-size: 16px;
    --vds-text-body-interface-small-code-font-family: Roboto;
    --vds-text-body-interface-small-code-font-weight: 400;
    --vds-text-body-interface-small-code-line-height: 1;
    --vds-text-body-interface-small-code-font-size: 14px;
    --vds-text-body-interface-small-code-letter-spacing: 0.16em;
    --vds-text-body-interface-small-regular-font-family: Roboto;
    --vds-text-body-interface-small-regular-font-weight: 400;
    --vds-text-body-interface-small-regular-line-height: 1;
    --vds-text-body-interface-small-regular-font-size: 14px;
    --vds-text-body-interface-small-extended-font-family: Roboto;
    --vds-text-body-interface-small-extended-font-weight: 400;
    --vds-text-body-interface-small-extended-line-height: 1.5;
    --vds-text-body-interface-small-extended-font-size: 14px;
    --vds-text-body-interface-small-strong-font-family: Roboto;
    --vds-text-body-interface-small-strong-font-weight: 500;
    --vds-text-body-interface-small-strong-line-height: 1;
    --vds-text-body-interface-small-strong-font-size: 14px;
    --vds-text-body-interface-small-heavy-font-family: Roboto;
    --vds-text-body-interface-small-heavy-font-weight: 700;
    --vds-text-body-interface-small-heavy-line-height: 1;
    --vds-text-body-interface-small-heavy-font-size: 14px;
    --vds-text-body-interface-small-password-visible-font-family: Roboto;
    --vds-text-body-interface-small-password-visible-font-weight: 400;
    --vds-text-body-interface-small-password-visible-line-height: 1;
    --vds-text-body-interface-small-password-visible-font-size: 14px;
    --vds-text-body-interface-small-password-visible-letter-spacing: 0.16em;
    --vds-text-body-interface-small-password-hidden-font-family: Roboto;
    --vds-text-body-interface-small-password-hidden-font-weight: 400;
    --vds-text-body-interface-small-password-hidden-line-height: 1;
    --vds-text-body-interface-small-password-hidden-font-size: 14px;
    --vds-text-body-interface-small-password-hidden-letter-spacing: 0.32em;
    --vds-spacing-min: 2px;
    --vds-spacing-inner: 4px;
    --vds-spacing-element: 8px;
    --vds-spacing-group: 16px;
    --vds-spacing-sections: 32px;
    --vds-friend-bestest: 2px;
    --vds-friend-best: 4px;
    --vds-friend-close: 8px;
    --vds-friend-aquaintance: 32px;
    --vds-size-xs: 8px;
    --vds-size-s: 16px;
    --vds-size-m: 24px;
    --vds-size-l: 32px;
    --vds-size-xl: 40px;
    --vds-size-xxl: 48px;
    --vds-size-huge: 64px;
    --vds-elevation-1-blur: 4px;
    --vds-elevation-1-spread: 0;
    --vds-elevation-1-color: rgba(hsla( 206, 12%, 51%, 1 ),0.64);
    --vds-elevation-1-type: dropShadow;
    --vds-elevation-1-offset-x: 0;
    --vds-elevation-1-offset-y: 2px;
    --vds-elevation-2-color: rgba(hsla( 206, 12%, 55%, 1 ),0.72);
    --vds-elevation-2-type: dropShadow;
    --vds-elevation-2-blur: 8px;
    --vds-elevation-2-spread: 0;
    --vds-elevation-2-offset-x: 0;
    --vds-elevation-2-offset-y: 2px;
    --vds-elevation-3-blur: 16px;
    --vds-elevation-3-spread: 0;
    --vds-elevation-3-color: rgba(hsla( 206, 12%, 61%, 1 ),0.80);
    --vds-elevation-3-type: dropShadow;
    --vds-elevation-3-offset-x: 0;
    --vds-elevation-3-offset-y: 4px;
    --vds-elevation-4-blur: 24px;
    --vds-elevation-4-spread: 0;
    --vds-elevation-4-color: rgba(hsla( 206, 12%, 66%, 1 ),0.88);
    --vds-elevation-4-type: dropShadow;
    --vds-elevation-4-offset-x: 0;
    --vds-elevation-4-offset-y: 8px;
    --vds-inset-focus-active-blur: 0;
    --vds-inset-focus-active-spread: 2px;
    --vds-inset-focus-active-color: #ffffff;
    --vds-inset-focus-active-type: innerShadow;
    --vds-inset-focus-active-offset-x: 0;
    --vds-inset-focus-active-offset-y: 0;
}

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
}

html, body {
    height: 100%;
}

* {
    box-sizing: border-box;
}

// This style clashes with Vapor
/*
.cdk-overlay-connected-position-bounding-box {
  justify-content: unset !important;
  align-items: flex-start !important;
}
*/

// The following styles fix conflicts between Vapor and Material: we can remove them as soon
// as we have all the UI using Vapor
h2 {
    font-size: revert !important;
}
.datatable-header-cell-label {
    font-family: Teko, sans-serif !important;
}
// .control-page {
//     .save-button {
//         span {
//             font-family: Teko, sans-serif !important;
//         }
//     }
//     .single-tag {
//         .mat-icon {
//             overflow: unset !important;
//         }
//     }
// }

// Fix Vapor style for the empty state
.ts-empty-state__text-container {
    margin-top: 20px;
}

// Workaround for the new order drawer
.new-order-drawer {
    .ts-vapor .ts-autocomplete .ng-select {
        width: 300px !important;
    }
}

// Workaround for the new resource drawer
.resource {
    .ts-vapor .ts-autocomplete .ng-select {
        width: 300px !important;
    }
}

// Workaround for all drawers
.mic-drawer {
    .ts-vapor .ts-panel {
        border-bottom: none !important;
    }

    .ts-vapor .ts-panel__title:not(.opened) {
        background-color: transparent !important;
    }

    // Workaround for select dropdowns inside drawers
    .ts-vapor .ng-dropdown-panel.ng-select-bottom {
        max-height: 152px !important;
    }
    .ng-dropdown-panel .ng-dropdown-panel-items {
        max-height: 150px !important;
    }
}

.ts-popup__bottom-bar:has(.ts-popup__bottom-bar__left) .ts-popup__bottom-bar__right .ts-vapor.ts-button-container{
    width: auto !important;
    max-width: 400px;
}

.ts-popup__bottom-bar:has(.ts-popup__bottom-bar__left) .ts-popup__bottom-bar__right {
    max-width: 800px;
    width: auto;
}

.ts-popup__bottom-bar .ts-popup__bottom-bar__left .ts-vapor.ts-button-container:first-of-type button{
    border: solid 1px #0090d1;
    color: #0090d1 !important;
}
.ts-popup__bottom-bar .ts-popup__bottom-bar__left .ts-vapor.ts-button-container:first-of-type button:hover{
    background-color: #0090d1 !important;
    color: white !important;
}

.cdk-overlay-pane.ts-overlay-center{
    position: sticky;
    top: 300px;
    margin-top: 300px;
    z-index: 2000;
}

.cdk-overlay-container.ts-vapor.ts-popup__container > div:last-of-type > div{
    max-width: 1000px;
}

.cdk-overlay-container.ts-vapor.ts-popup__container > div:last-of-type > div > div{
    max-width: 1000px;
}

#cdk-overlay-200 > div > ts-bottom-bar{
    max-width: 1000px;
}

.ts-popup__bottom-bar__right{
    max-width: 700px !important;
}

#cdk-overlay-200 > div > ts-bottom-bar > div.ts-popup__bottom-bar__right{
    max-width: 700px !important;
}

.treelist-element.ts-scrollable .dx-treelist-headers {
    z-index: 5 !important;
}

.dx-datebox {
    z-index: 5 !important;
}

// Remove validation text for the filters of the orders list page:
// there is no validation there and it only takes space
.orders-list-page {
    .ts-vapor .ts-validation-text {
        display: none !important;
    }
}

.resources-page {
    .dx-treelist-icon-container >.dx-treelist-empty-space:nth-of-type(1):not(:last-of-type){
    width: 40px !important;
    min-width: 40px !important;
    }
    .dx-treelist-icon-container >.dx-treelist-empty-space:nth-of-type(2):not(:last-of-type){
    width: 54px !important;
    min-width: 54px !important;
    }
    .dx-treelist-icon-container >.dx-treelist-empty-space:nth-of-type(3){
    width: 0px !important;
    min-width: 0px !important;
    }
}

.res-form {
    .table-header-caption{
        margin-left: 35px;
        margin-top: 4px;
    }
    .dx-treelist-select-all .dx-checkbox {
        left: 8px;
    }
}

.gen-dialog-wrp {
    span {
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;        
    }
    button {
        padding: 12px 16px !important;
        border-radius: 8px !important;
    }
    .save:not(:active) *{
        background-color: #0077AD !important;
        color: white;
    }
    .save:hover *{
        background-color: rgb(0, 144, 209) !important;
    }
    .cancel *{
        text-decoration: underline !important;
    }
    .cancel:hover button{
        border: 1px solid rgb(0, 144, 209) !important;;
    }
}

.ts-vapor{
    .ts-ui-shell__container__sidebar{
      z-index: 1 !important;
    }

    .ts-ui-shell-sidebar__opened {
        width: 248px !important;
    }
}

.cdk-overlay-container:has(ts-notification) {
    .cdk-global-overlay-wrapper {
        padding-top: 40px!important;
    }
    z-index: 1300!important;
}

.dx-treelist .dx-treelist-content .dx-treelist-table .dx-row > td:not(.dx-validation-pending):not(.dx-treelist-select-all) {
    border: none;
}

.ts-vapor.ts-tab-panel__container div.tab-panel__tab-list .tab-item-container:not(.tab-item__disabled):not(.tab-item__active):not(.tab-item__progressive):focus:focus:not(:disabled):not(:hover), .ts-vapor.ts-tab-panel__container div.tab-panel__tab-list .tab-item-container:not(.tab-item__disabled):not(.tab-item__active):not(.tab-item__progressive):focus:focus-visible:not(:disabled):not(:hover), .ts-vapor.ts-tab-panel__container div.tab-panel__tab-list.tab-panel__secondary .tab-item-container:not(.tab-item__disabled):not(.tab-item__progressive):focus:focus:not(:disabled):not(:hover), .ts-vapor.ts-tab-panel__container div.tab-panel__tab-list.tab-panel__secondary .tab-item-container:not(.tab-item__disabled):not(.tab-item__progressive):focus:focus-visible:not(:disabled):not(:hover),
.ts-vapor .ng-select:focus:not(:disabled):not(:hover), .ts-vapor .ng-select:focus-visible:not(:disabled):not(:hover),
.ts-vapor.ts-button .tertiary.cta:focus:not(:disabled):not(:hover), .ts-vapor.ts-button .tertiary.cta:focus-visible:not(:disabled):not(:hover), .ts-vapor.ts-button .tertiary.blue:focus:not(:disabled):not(:hover), .ts-vapor.ts-button .tertiary.blue:focus-visible:not(:disabled):not(:hover),
.ts-vapor .ts-text-area:focus:not(:disabled):not(:hover), .ts-vapor .ts-text-area:focus-visible:not(:disabled):not(:hover),
.ts-vapor .ts-textbox__input:focus:not(:disabled):not(:hover), .ts-vapor .ts-textbox__input:focus-visible:not(:disabled):not(:hover),
.ts-vapor.ts-tab-panel__container div.tab-panel__tab-list.tab-panel__primary .tab-item-container.tab-item__active:not(.tab-item__disabled):not(.tab-item__progressive):focus:focus:not(:disabled):not(:hover), .ts-vapor.ts-tab-panel__container div.tab-panel__tab-list.tab-panel__primary .tab-item-container.tab-item__active:not(.tab-item__disabled):not(.tab-item__progressive):focus:focus-visible:not(:disabled):not(:hover) {
    z-index: 1 !important;
    cursor: pointer !important;
    outline-width: 0px !important;
    outline-style:  none !important;
    outline-color: transparent !important;
    outline-offset: 0px !important;
    position: relative !important;
}

.ts-vapor .ts-accordion.focused:not(.error) {
    outline-color: transparent!important;
}

#internal-treelist-widget .dx-row.dx-data-row:hover > td {
    .rank-input {
        background-color: var(--vds-dataviz-table-bg-hover);
    }
}

#internal-treelist-widget .dx-row.dx-data-row.dx-selection > td {
    .rank-input {
        background-color: var(--vds-dataviz-table-bg-active);
    }
}

#internal-treelist-widget .dx-row.dx-data-row > td.dx-editor-cell:not(.dx-command-select):not(.dx-command-edit) {
    border: 0!important;
    border-bottom: var(--vds-border-size-xs) solid var(--vds-dataviz-table-border-default)!important;
}

ts-tab-panel.ts-tab-panel__container.ts-vapor {
    .tab-item-container {
        min-width: 100px;
        > span {
            width: 100%;
        }
    }
}

.ts-popup {
    .ts-popup__body {
        min-width: 460px;
    }
    
    vapor-angular-button.ts-vapor.ts-button {
        width: min-content!important;
        > button {
            width: min-content!important;
        }
    }
}